class AppSettings {
  private values: any[] = [
    {
      VUE_APP_DOMAIN: "localhost:8080",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"http://localhost:8080/",
      VUE_APP_API_DOMAIN:"https://localhost:57679/",
      VUE_APP_TENANT_ID: "9f66153a-1411-482f-9c63-2fc4cb595634",
      VUE_APP_TENANT_NAME: "localhost",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF: "https://localhost:57681/"
    },
    {
      VUE_APP_DOMAIN: "daisy-sstrn.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-sstrn.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy-sstrn.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://daisyapi-sstrn.ressif-dev.com/",
      VUE_APP_TENANT_ID: "c3de1cd3-6b65-45cf-a479-b6852bea7528",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF: "https://myressifapi-sstrn.ressif-dev.com/"
    },
    {
      VUE_APP_DOMAIN: "daisy-actis.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-actis.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy-actis.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://daisyapi-actis.ressif-dev.com/",
      VUE_APP_TENANT_ID: "c341c9a0-e03a-4b11-be87-d8bbfea82399",
      VUE_APP_TENANT_NAME: "ACTIS",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF: "https://myressifapi-actis.ressif-dev.com/"
    },
    {
      VUE_APP_DOMAIN: "daisy-asia.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-asia.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy-asia.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://daisyapi-asia.ressif-dev.com/",
      VUE_APP_TENANT_ID: "6fa4b9be-9411-48e6-879c-53505fae2a8c",
      VUE_APP_TENANT_NAME: "ASIA",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF: "https://myressifapi-asia.ressif-dev.com/"
    },
    {
      VUE_APP_DOMAIN: "daisy.sstrn.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.sstrn.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.sstrn.ressif-apps.com/",
      VUE_APP_TENANT_ID: "9f66153a-1411-482f-9c63-2fc4cb595634",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.sstrn.ressif-apps.com/",
    },
    {
      VUE_APP_DOMAIN: "daisy.actis.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.actis.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.actis.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.actis.ressif-apps.com/",
      VUE_APP_TENANT_ID: "c341c9a0-e03a-4b11-be87-d8bbfea82399",
      VUE_APP_TENANT_NAME: "ACTIS",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.actis.ressif-apps.com/",
    },
    {
      VUE_APP_DOMAIN: "daisy.asia.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.asia.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.asia.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.asia.ressif-apps.com/",
      VUE_APP_TENANT_ID: "6fa4b9be-9411-48e6-879c-53505fae2a8c",
      VUE_APP_TENANT_NAME: "ASIA",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.asia.ressif-apps.com/",
    },    
    {
      VUE_APP_DOMAIN: "daisy.asia.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.asia.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.asia.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.asia.ressif-staging.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "ASIA",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.asia.ressif-staging.com/",
    },
    {
      VUE_APP_DOMAIN: "daisy.sstrn.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.sstrn.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.sstrn.ressif-staging.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.sstrn.ressif-apps.com/",
    },
    {
      VUE_APP_DOMAIN: "daisy.actis.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.actis.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://daisy.actis.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://daisy-api.actis.ressif-staging.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "ACTIS",
      VUE_APP_CLIENT_NAME: "daisy",
      VUE_APP_API_MYRESSIF:"https://myressif-api.actis.ressif-apps.com/",
    }
  ]

  public getApiURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_API_DOMAIN
    else
      return "ERROR-URL"
  }
  

  public getClientName() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_CLIENT_NAME
    else
      return 'vuejs_code_client'
  }
  
  public getFrontURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_FRONT_DOMAIN
    else
      return "ERROR-URL"
  }

  public getIdentityURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_IDENTITY_DOMAIN
    else
      return "ERROR-URL"
  }

  public getTenantId() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_TENANT_ID
    else
      return "ERROR-ID"
  }

  public getTenantName(){
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_TENANT_NAME
    else  
      return "Tenant"
  }
  public getMyRessifApiURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_API_MYRESSIF
    else
      return "ERROR-URL"
  }
}
const appS = new AppSettings()
export default appS