import api from '@/libs/axios'
import { FilterModel, GeneratePowerpointModel, ExtraFilterCreateUpdateModel, ExtraFilterValueCreateUpdateModel, RapportActiviteModel } from '../models/rapportActivites/rapportActivite'
import { TypeGraphEnum } from '../models/enums/typeGraph'

const resource = 'RapportActivites'

export default class RapportActivitesRessource {

  public async number(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/number`, data)
    return response.data
  }

  public async repartitionHFByDossiersWithPI(data: RapportActiviteModel, specificId: any) : Promise<any> {
    data.statTypeSpecificId = specificId;
    const response = await api.post(`${resource}/repartitionHFByDossiersWithPI`, data);
    return response.data;
  }
 
  public async exportPPT(data: GeneratePowerpointModel) : Promise<any> {
    const response = await api.post(`${resource}/exportPPT`, data, {responseType: "arraybuffer", timeout: 1980000})
    return response.data
  }

  public async extraFilters(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/filters`, {
      params: params,
    });
    return response.data;
  }

  public async extraFiltersByTypeGraph(
    typeGraph?: TypeGraphEnum,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (typeGraph) params.append("TypeGraph", typeGraph.toString());
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/filtersTypeGraph`, {
      params: params,
    });
    return response.data;
  }
  
  public async extraFilterById(id: string) : Promise<void> {
    const response = await api.get(`${resource}/filters/${id}`)
    return response.data;
  }

  public async createExtraFilter(data : ExtraFilterCreateUpdateModel) : Promise<void> {
    const response = await api.post(`${resource}/filters`, data);
    return response.data;
  }

  public async deleteExtraFilter(id: string) : Promise<void> {
    const response = await api.delete(`${resource}/filters/${id}`)
    return response.data;
  }

  public async updateExtraFilter(id: string, data : ExtraFilterCreateUpdateModel) : Promise<void> {
    const response = await api.put(`${resource}/filters/${id}`, data);
    return response.data;
  }

  public async createExtraFilterValue(id: string, data : ExtraFilterValueCreateUpdateModel) : Promise<void> {
    const response = await api.post(`${resource}/filters/${id}/valeurs`, data);
    return response.data;
  }

  public async deleteExtraFilterValue(id: string, valeurId: string) : Promise<void> {
    const response = await api.delete(`${resource}/filters/${id}/valeurs/${valeurId}`)
    return response.data;
  }

  public async updateExtraFilterValue(id: string, valueId: string, data : ExtraFilterValueCreateUpdateModel) : Promise<void> {
    const response = await api.put(`${resource}/filters/${id}/valeurs/${valueId}`, data);
    return response.data;
  }

  public async demandes(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ) {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    // if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/demandes`, {
      params: params,
    });
    return response.data;
  }
}
