import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { PassageInfirmerie, UpdatePassageInfirmerieDto } from '../models/dossiers/passageInfirmerie';

const baseResource = 'Dossiers'
const resource = 'PassagesInfirmerie'

export default class PassagesInfirmerieResource {

  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<PassageInfirmerie>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params } )
    return response.data
  }

  public async getById(dossierId: string, id: string) {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async post(dossierId: string, data: PassageInfirmerie, config:any): Promise<PassageInfirmerie> {
    let formData = new FormData();

    if (data.dossierId) formData.append('dossierId', data.dossierId.toString());
    
    // General
    if (data.horaire) formData.append('horaire', data.horaire.toString());
    if (data.datePassageInfirmerie) formData.append('datePassageInfirmerie', data.datePassageInfirmerie.toString());
    if (data.duree) formData.append('duree', data.duree.toString());
    if (data.situationProfessionnelleId) formData.append('situationProfessionnelleId', data.situationProfessionnelleId.toString());
    if (data.origineId) formData.append('origineId', data.origineId.toString());
    if (data.typeVisiteId) formData.append('typeVisiteId', data.typeVisiteId.toString());   
    if (data.typeSuiviId) formData.append('typeSuiviId', data.typeSuiviId.toString());
    if (data.precisionNatureTypeVisiteId) formData.append('precisionNatureTypeVisiteId', data.precisionNatureTypeVisiteId.toString());
    if (data.detailTypeVisiteId) formData.append('detailTypeVisiteId', data.detailTypeVisiteId.toString());
    data.actionsRealiseesId?.forEach((id: any) => formData.append('actionsRealiseesId', id.toString()));

    // Examens complémentaires
    if (data.audiogramme) formData.append('audiogramme', data.audiogramme.toString());
    if (data.taille) formData.append('taille', data.taille.toString());
    if (data.poid) formData.append('poid', data.poid.toString());
    if (data.prelevementSanguin) formData.append('prelevementSanguin', data.prelevementSanguin.toString());
    if (data.controleVisuel) formData.append('controleVisuel', data.controleVisuel.toString());
    if (data.glycemieCapilaire) formData.append('glycemieCapilaire', data.glycemieCapilaire.toString());
    if (data.temperature) formData.append('temperature', data.temperature.toString());
    if (data.bandeletteUrinaire) formData.append('bandeletteUrinaire', data.bandeletteUrinaire.toString());
    if (data.controleTA) formData.append('controleTA', data.controleTA.toString());
    if (data.testRespiratoire) formData.append('testRespiratoire', data.testRespiratoire.toString());
    if (data.testCovid) formData.append('testCovid', data.testCovid.toString());
    if (data.sao2) formData.append('sao2', data.sao2.toString());
    if (data.autresExamensRealises) formData.append('autresExamensRealises', data.autresExamensRealises.toString());

    // Diagnostic infirmerie
    data.raisonsVenueId?.forEach((id: any) => formData.append('raisonsVenueId', id?.toString()));
    data.precisionsRaisonsVenueId?.forEach((id: any) => formData.append('precisionsRaisonsVenueId', id?.toString()));

    //Douleurs
    data.typeDouleursPhysiques?.forEach((douleursPhysiques: any, index) => {
      this.appendArray(formData, douleursPhysiques, `typeDouleursPhysiques[${index}]`)
    })
    data.douleursPsychiques?.forEach((douleursPsychiques: any, index) => {
      this.appendArray(formData, douleursPsychiques, `douleursPsychiques[${index}]`)
    })

    // Bilan
    if (data.orientationId) formData.append('orientationId', data.orientationId.toString());
    if (data.satisfaction) formData.append('satisfaction', data.satisfaction.toString());
    if (data.periodicite) formData.append('periodicite', data.periodicite.toString());
    if (data.stress) formData.append('stress', data.stress.toString());

    data.pointsNegatifs?.forEach((id: any) => formData.append('pointsNegatifs', id.toString()));
    data.pointsPositifs?.forEach((id: any) => formData.append('pointsPositifs', id.toString()));

    if (data.note) formData.append('note', data.note.toString());
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    
    const response = await api.post(
      `${baseResource}/${dossierId}/${resource}`,
      formData,
      config
    );
    return response.data;
  }

  public async delete(dossierId: string, id : string) : Promise<PassageInfirmerie> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async deleteFile(dossierId:number, passageInfirmerieId: number, fileId: number) : Promise<void> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${passageInfirmerieId}/files/${fileId}`)
    return response.data
  }

  public async put(dossierId: string, passageInfirmerieId: string, data: UpdatePassageInfirmerieDto, config:any): Promise<number> {
    let formData = new FormData();

    if (data.dossierId) formData.append('dossierId', data.dossierId.toString());
    if (data.passageInfirmerieId) formData.append('passageInfirmerieId', data.passageInfirmerieId.toString());
    
    // General
    if (data.horaire) formData.append('horaire', data.horaire.toString());
    if (data.datePassageInfirmerie) formData.append('datePassageInfirmerie', data.datePassageInfirmerie.toString());
    if (data.duree) formData.append('duree', data.duree.toString());
    if (data.situationProfessionnelleId) formData.append('situationProfessionnelleId', data.situationProfessionnelleId.toString());
    if (data.origineId) formData.append('origineId', data.origineId.toString());
    if (data.typeVisiteId) formData.append('typeVisiteId', data.typeVisiteId.toString());   
    if (data.typeSuiviId) formData.append('typeSuiviId', data.typeSuiviId.toString());
    if (data.detailTypeVisiteId) formData.append('detailTypeVisiteId', data.detailTypeVisiteId.toString());
    if (data.precisionNatureTypeVisiteId) formData.append('precisionNatureTypeVisiteId', data.precisionNatureTypeVisiteId.toString())
    data.actionsRealiseesId?.forEach((id: any) => formData.append('actionsRealiseesId', id.toString()));

    // Examens complémentaires
    if (data.audiogramme) formData.append('audiogramme', data.audiogramme.toString());
    if (data.taille) formData.append('taille', data.taille.toString());
    if (data.poid) formData.append('poid', data.poid.toString());
    if (data.prelevementSanguin) formData.append('prelevementSanguin', data.prelevementSanguin.toString());
    if (data.controleVisuel) formData.append('controleVisuel', data.controleVisuel.toString());
    if (data.glycemieCapilaire) formData.append('glycemieCapilaire', data.glycemieCapilaire.toString());
    if (data.temperature) formData.append('temperature', data.temperature.toString());
    if (data.bandeletteUrinaire) formData.append('bandeletteUrinaire', data.bandeletteUrinaire.toString());
    if (data.controleTA) formData.append('controleTA', data.controleTA.toString());
    if (data.testRespiratoire) formData.append('testRespiratoire', data.testRespiratoire.toString());
    if (data.testCovid) formData.append('testCovid', data.testCovid.toString());
    if (data.sao2) formData.append('sao2', data.sao2.toString());
    if (data.autresExamensRealises) formData.append('autresExamensRealises', data.autresExamensRealises.toString());

    // Diagnostic infirmerie
    data.raisonsVenueId?.forEach((id: any) => formData.append('raisonsVenueId', id?.toString()));
    data.precisionsRaisonsVenueId?.forEach((id: any) => formData.append('precisionsRaisonsVenueId', id?.toString()));

    //Douleurs
    data.typeDouleursPhysiques?.forEach((douleursPhysiques: any, index) => {
      this.appendArray(formData, douleursPhysiques, `typeDouleursPhysiques[${index}]`)
    })
    data.douleursPsychiques?.forEach((douleursPsychiques: any, index) => {
      this.appendArray(formData, douleursPsychiques, `douleursPsychiques[${index}]`)
    })

    // Bilan
    if (data.orientationId) formData.append('orientationId', data.orientationId.toString());
    if (data.satisfaction) formData.append('satisfaction', data.satisfaction.toString());
    if (data.periodicite) formData.append('periodicite', data.periodicite.toString());
    if (data.stress) formData.append('stress', data.stress.toString());

    data.pointsNegatifs?.forEach((id: any) => formData.append('pointsNegatifs', id.toString()));
    data.pointsPositifs?.forEach((id: any) => formData.append('pointsPositifs', id.toString()));

    if (data.note) formData.append('note', data.note.toString());
    if (data.noteId) formData.append('noteId', data.noteId!.toString());
    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    
    const response = await api.put(
      `${baseResource}/${dossierId}/${resource}/${passageInfirmerieId}`,
      formData,
      config
    );
    return response.data;
  }

  appendArray(form_data: any, values: any, name: any) {
    if (!values && name) form_data.append(name, "");
    else {
      if (typeof values == "object") {
        for (let key in values) {
          if (typeof values[key] == "object")
            this.appendArray(form_data, values[key], name + "[" + key + "]");
          else if (values[key] != undefined)
            form_data.append(name + "[" + key + "]", values[key]);
        }
      } else form_data.append(name, values);
    }

    return form_data;
  }
}
